import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Form, FormGroup } from 'reactstrap';

const Skill = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const truncateText = (text) => {
    return text.length > 10 ? text.substring(0, 10) + '...' : text;
  };

  return (
    <React.Fragment>
      <div className='container'>
        <div className='row '>
          <div className='col-12'>
            <div outline onClick={toggleModal}>
              <b> Skill</b>
            </div>
          </div>
        </div>
      </div>
      <div className=' container modal-skill'>
        <Modal
          className='my-modals'
          isOpen={isModalOpen}
          toggle={toggleModal}
          style={{ maxWidth: '1000px', width: '100%', height: '100%' }}
        >
          <ModalHeader toggle={toggleModal}>
            <h2 className='about-t'>MY SKILLS</h2>
          </ModalHeader>
          <ModalBody>
            <div className='row '>
              <div className='col-lg-12 about-tt'>
                <Form>
                  <h1>
                    My toolbox for Full Stack Development magic employs a full
                    compliment of technologies!
                  </h1>

                  <FormGroup>
                    <h1 style={{ color: '#e9d5b8' }}>PROFICIENT</h1>
                  </FormGroup>

                  {/* FormGroups with truncation and hover tooltip */}
                  <FormGroup>
                    <h4 title="Frontend Development Skills">
                      {truncateText('Frontend Development Skills')}
                    </h4>
                    <div className='percent'>
                      <div style={{ width: '100%' }}></div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <h4 title="Backend Development Skills">
                      {truncateText('Backend Development Skills')}
                    </h4>
                    <div className='percent'>
                      <div style={{ width: '100%' }}></div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <h4 title="Cloud Computing Skills">
                      {truncateText('Cloud Computing Skills')}
                    </h4>
                    <div className='percent'>
                      <div style={{ width: '100%' }}></div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <h4 title="Development Tools and Methodologies">
                      {truncateText('Development Tools and Methodologies')}
                    </h4>
                    <div className='percent'>
                      <div style={{ width: '100%' }}></div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <h4 title="Soft Skills & Collaboration">
                      {truncateText('Soft Skills & Collaboration')}
                    </h4>
                    <div className='percent'>
                      <div style={{ width: '100%' }}></div>
                    </div>
                  </FormGroup>

                  <div className='row'>
                    <div className='col-12 resume-btn'>
                      <button>
                        <a
                          href='https://ttewabe.github.io/html-js/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          RESUME
                        </a>
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Skill;
