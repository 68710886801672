import React from 'react';
import ReactDOM from 'react-dom/client'; // Notice the updated import path
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'typeface-lobster';
import 'typeface-open-sans';
import 'font-awesome/css/font-awesome.css';
import 'bootstrap-social/bootstrap-social.css';
import App from './App';

// Create a root element using the new API
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the app inside the root element
root.render(<App />);
